<template>
  <UiPopup
    :model-value="modelValue"
    title="Add new phone number"
    :description="`You are about to add a new local number for the country ${location.country.text}. After adding the number, check the activity and SMS settings in the table.`"
    primary-button-text="Add number"
    secondary-button-text="Close"
    size="medium"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="my-4">
      <p class="text-caption mb-2 text-center text-black-60">number type</p>
      <div class="flex rounded-xl bg-black-03 p-0.5 text-black-70">
        <button
          v-for="item in typeItems"
          :key="item.value"
          class="text-subhead-2 w-full p-1.5"
          :class="
            data.phone.type_id === item.value
              ? 'text-subhead-1 rounded-[10px] bg-secondary-50 text-black-100 shadow'
              : 'text-subhead-2'
          "
          @click.stop="data.phone.type_id = item.value"
        >
          {{ item.text }}
        </button>
      </div>
    </div>
    <Transition name="fade" mode="out-in">
      <UiInputPhone
        v-if="data.phone.type_id === LOCAL_NUMBER_TYPES.COMPANY"
        v-model="data.phone"
        name="Phone"
        label="Phone number"
        placeholder="Add number"
        :error="useGetFieldErrors(v$, ['phone'])"
      />
      <UiInputPhone
        v-else-if="verifiedPhoneSelected"
        v-model="data.phone"
        name="Phone"
        label="Phone number"
        placeholder="Add number"
        class="pointer-events-none"
      />
      <div v-else>
        <UiInputCustomMenu
          ref="numbersRef"
          name="showNumbers"
          class="h-full"
          align-center
          position-top
          menu-classes="overflow-hidden"
        >
          <template #activator>
            <UiInputSearch
              id="search-numbers-field"
              v-model="search"
              placeholder="Search for phone number"
              @search:debounced="searchNumbers"
            />
          </template>

          <template #content>
            <div
              v-if="search && search.length > 2"
              id="search-numbers-content"
              class="top-full mt-0.5 overflow-hidden rounded-xl bg-white px-1 py-1.5 text-left shadow"
            >
              <div class="styled-scrollbar max-h-[200px] overflow-y-auto overflow-x-hidden">
                <UiLoader v-if="loading" class="mx-6 mt-2 !w-auto" />
                <div
                  v-else-if="!phones.length"
                  id="empty-search-results"
                  class="text-caption rounded-md bg-secondary-30 px-3 py-2"
                >
                  This number is either not in the database or not verified.
                </div>
                <template v-else>
                  <div
                    v-for="item in phones"
                    :key="item.id"
                    class="group w-full cursor-pointer bg-transparent px-3 py-2 transition hover:bg-black-03"
                    @click="selectPhone(item)"
                  >
                    <p class="text-body-2">+{{ item.code }}{{ item.phone }}</p>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </UiInputCustomMenu>
      </div>
    </Transition>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Phone, Local, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'
import { LOCAL_NUMBER_TYPES } from '~/constants'

const emits = defineEmits(['update:modelValue', 'input'])

const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  location?: Local
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  location: undefined,
})

const loading = ref(false)
const search = ref('')
const phones = ref<any[]>([])
const numbersRef = ref<HTMLElement | null>(null)

const data = ref({
  country_id: props.location ? props.location.country.value : null,
  phone: {
    phone_country_id: props.location ? props.location.country.value : null,
    phone: null,
    type_id: LOCAL_NUMBER_TYPES.COMPANY,
  },
})

const typeItems = ref<InputItem[]>()

const verifiedPhoneSelected = ref(false)

const getPhoneTypes = async () => {
  try {
    typeItems.value = useSerializeLibraryItems(await useLocalPhoneTypes()).reverse()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

onNuxtReady(async () => {
  await getPhoneTypes()
})

const selectPhone = (item: any) => {
  data.value.phone.phone_country_id = item.country_id
  data.value.phone.phone = item.phone
  verifiedPhoneSelected.value = true
  numbersRef.value?.closeMenu()
}

const validatePhone = (value: Phone) => {
  return useValidatePhone(value.phone, value.phone_country_id)
}

const rules = computed(() => ({
  phone: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
}))

const v$ = useVuelidate(rules, data, { $stopPropagation: true })

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  try {
    const payload = {
      country_id: data.value.country_id,
      phones: [{ ...data.value.phone, phone: data.value.phone.phone?.toString() }],
    }
    await useCreatePhone(payload)
    emits('input', data.value)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const searchNumbers = async () => {
  if (search.value.length < 3) return
  numbersRef.value?.openMenu()
  loading.value = true
  try {
    const { data } = await useGetVerifiedNumbers(search.value)
    phones.value = data
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>
